import React, { useEffect, useState } from "react";
import Footer from "../../page/Footer";
import Navbar from "../../page/Navbar";
import Subscription from "./Subscription";
import "./Partners.css";
import AWS from "aws-sdk";
import Mybookings from "../Login User/Mybookings";
import PostHotelData from "../../Partners components/PostHotelData";
import PostVehicleData from "../../Partners components/PostVehicleData";
import { useAuthenticator } from "@aws-amplify/ui-react";

export default function Partners() {
  const { user } = useAuthenticator((context) => [context.user]);
  const loginUserName = user?.signInDetails?.loginId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Subscription-User-list",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);
// Addd ! below
  if (!loginUserName) { 
    return;
  }
  const loginUserAsset = data?.filter((item) => item?.email === loginUserName);
  const SubPaidUser = loginUserAsset?.length > 0;

  return (
    <>
      <Navbar />
      <div className="Partners_section">
        <h2 className="Partner_titel">Welcome to OotyGO Partner Section</h2>
        <div className="Partner_Signup">
          {SubPaidUser ? (
            <>
              <Mybookings /> <br />
              <div className="Partner_Upload_sec">
                <PostHotelData />

                <PostVehicleData />
              </div>
            </>
          ) : (
            <div>
              <Subscription />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
