import React from "react";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "./Mybookings.css";
import { MdDeleteForever } from "react-icons/md";
import { DynamoDBClient, DeleteItemCommand } from "@aws-sdk/client-dynamodb";
import { useNavigate } from "react-router-dom";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
function Mybookings() {
  const { user } = useAuthenticator((context) => [context.user]);
  const [data, setData] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  // Initialize AWS SDK
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  // Get Hotels
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-hotel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);
  // get Vehicle
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-travel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setVehicle(result.Items);
      }
    });
  }, []);

  const loginUserName = user.signInDetails.loginId;

  const loginUserHotel = data.filter((item) => item.username === loginUserName);
  const loginUserVehicle = vehicle.filter(
    (item) => item.username === loginUserName
  );

  // delet vehicle
  const client = new DynamoDBClient({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  });
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/SignUp");
  };
  const deleteItem = async (id, name) => {
    const userConfirmed = window.confirm(`You are deleting ${name}`);
    if (!userConfirmed) {
      return;
    }
    const params = {
      TableName: "Ootygo-hotel",
      Key: {
        id: { N: id.toString() },
        name: { S: name },
      },
    };

    try {
      const command = new DeleteItemCommand(params);
      await client.send(command);
      window.alert("Asset Deleted");
      handleClick();
    } catch (error) {
      console.error("Error deleting item:", error);
      window.alert("Error In Deleteing");
    }
  };

  const deleteItemvh = async (id, name, car) => {
    const userConfirmed = window.confirm(`You are deleting ${car}`);
    if (!userConfirmed) {
      return;
    }
    const params = {
      TableName: "Ootygo-travel",
      Key: {
        id: { S: id.toString() },
        name: { S: name },
      },
    };
    
    try {
      const command = new DeleteItemCommand(params);
      await client.send(command);
      window.alert("Asset Deleted");
      handleClick();
    } catch (error) {
      console.error("Error deleting item:", error);
      window.alert("Error In Deleteing");
    }
  };

  return (
    <>
      <div className="Mybooking">
        <h1 className="Mybooking_title">My Assets</h1>
        <div className="MyAssets">
          {loginUserHotel.map(({ id, name, imgurl, rate }, index) => (
            <div key={index} className="Mybooking_contant_Vehicles">
              <img src={imgurl} alt="test-img" className="Stay_hotel_img" />
              <h3 className="Travel_Vehicle_title">{name}</h3>
              <div className="Travel_Rete">
                Pkg starts @ <b>{rate}</b>
              </div>

              <button>Delete and Update</button>
              <button
                onClick={() => deleteItem(id, name)}
                className="Delete_vehicle"
              >
                <MdDeleteForever />
              </button>
            </div>
          ))}
          {loginUserVehicle.map(({ id, name, imgurl, pkg, car }, index) => (
            <div key={index} className="Mybooking_contant_Vehicles">
              <img src={imgurl} alt="test-img" className="Stay_hotel_img" />
              <h3 className="Travel_Vehicle_title">{name}</h3>
              <div className="Travel_Rete">
                Pkg starts @ <b>{pkg}</b>
              </div>

              <button>Delete and Update</button>
              <button
                onClick={() => deleteItemvh(id, name, car)}
                className="Delete_vehicle"
              >
                <MdDeleteForever />
              </button>
            </div>
          ))}
          
        </div>
        
      </div>
      <hr />
      <p className="Mybooking_Add_Title">Add your asset below <span><BsFillArrowDownCircleFill /></span></p>
    </>
  );
}
export default Mybookings;
