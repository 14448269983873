import React from "react";
// import axios from "axios";
import "./Subscription.css";
import { GoDotFill } from "react-icons/go";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { FaSquareWhatsapp } from "react-icons/fa6";
import AWS from "aws-sdk";
// import { useNavigate } from "react-router-dom";
const SubscriptionComponent = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const docClient = new AWS.DynamoDB.DocumentClient();
  // const navigate = useNavigate();
  // const handleClick = () => {
  //   navigate("/Partners");
  // };
  const handleSubscription = async () => {
    const params = {
      TableName: "Subscription-User-list",
      Item: {
        email: user.signInDetails.loginId,
        amount: "0",
        plan: "Free",
      },
    };

    try {
      await docClient.put(params).promise();
      window.alert("Free plan activation successful.");
      window.location.reload();
    } catch (err) {
      console.error("Error", err);
    }
  };

  const subscriptionPlans = [
    {
      id: "plan_1",
      name: "Free",
      price: (
        <div>
          <p className="Price">₹ 0/month</p>
          <p>
            <GoDotFill /> 1 Asset
          </p>
          <p>
            <GoDotFill /> 3 Photo 1 Video/Asset
          </p>
          <p>
            <GoDotFill /> Listed Normal
          </p>
        </div>
      ),
      action: handleSubscription,
    },
    {
      id: "plan_2",
      name: "Super",
      price: (
        <div>
          <p className="Price">₹ 999/month</p>
          <p>
            <GoDotFill /> Up To 5 Asset
          </p>
          <p>
            <GoDotFill /> 10 Photos 2 Video/Asset
          </p>
          <p>
            <GoDotFill /> Listed In The Top 10
          </p>
        </div>
      ),
      paylink: "https://rzp.io/rzp/mtBWn9D",
    },
    {
      id: "plan_3",
      name: "Super Ultimate",
      price: (
        <div>
          <p className="Price">₹ 1999/month</p>
          <p>
            <GoDotFill /> Up To 10 Asset
          </p>
          <p>
            <GoDotFill /> 20 Photo 3 Video/Asset
          </p>
          <p>
            <GoDotFill /> Listed In The Top 5
          </p>
        </div>
      ),
      paylink: "https://rzp.io/rzp/t2q0tw41",
    },
  ];

  const whatsappchat = `https://wa.me/919655062118?text=${encodeURIComponent(
    "Hi I have subscribed to OotyGO"
  )}`;

  return (
    <div>
      <h2 className="Sub_titel">Subscribe a plan to start</h2>
      <div className="Plans">
        {subscriptionPlans.map((plan) => (
          <span key={plan.id}>
            <span className="Plan_Selector">
              <h3>{plan.name}</h3>
              <label>{plan.price}</label>
              <button
                className="Sub_Btn"
                onClick={plan.action ? plan.action : null}
              >
                {plan.paylink ? (
                  <a href={plan.paylink}>Start Now</a>
                ) : (
                  "Start Now"
                )}
              </button>
            </span>
          </span>
        ))}
      </div>
      <h5 className="Payment_Email">
        * After the subscription, Email the payment & KYC(Any Id) details to
        ootygo.official@gmail.com or{" "}
        <a href={whatsappchat}>
          <span>
            <FaSquareWhatsapp />
          </span>
        </a>
        .
      </h5>
      <h5 className="Payment_Email">
        * After a basic verification, the Asset will be displayed on
        www.ootygo.in.
      </h5>
      <h5 className="Payment_Email">
        * For support{" "}
        <button onClick={() => (window.location.href = `tel:8838724178`)}>
          Call
        </button>
      </h5>
    </div>
  );
};

export default SubscriptionComponent;
