import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "./PostHotelData.css";
import { ClipLoader } from "react-spinners";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
// Configure AWS SDK
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();
const docClient = new AWS.DynamoDB.DocumentClient();

const PostVehicleData = () => {
  const initialFormData = {
    id: "",
    name: "",
    car: "",
    imgurl: "",
    number: "",
    pkg: "",
    sitting: "",
    username: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const { user } = useAuthenticator((context) => [context.user]);
  useEffect(() => {
    if (user) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        username: user.signInDetails.loginId,
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "number" ? Number(value) : value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const uploadFile = async (file) => {
    const params = {
      Bucket: "ootygo-travel-partner",
      Key: file.name,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    return data.Location;
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/SignUp");
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const imgurl = await uploadFile(formData.imgurl);

      const params = {
        TableName: "Ootygo-travel",
        Item: {
          ...formData,
          imgurl,
        },
      };

      await docClient.put(params).promise();
      setLoading(false);
      alert("Data saved successfully!");
      setFormData(initialFormData);
      handleClick();
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save data.");
      setLoading(false);
    }
  };
  // Update Id
  const [lastItemId, setLastItemId] = useState(0);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-travel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        const maxIdObject = result.Items?.reduce(
          (prev, current) => (prev.id > current.id ? prev : current),
          { id: -Infinity }
        );

        if (maxIdObject && maxIdObject.id !== -Infinity) {
          const maxId = Number(maxIdObject.id);
          setLastItemId(maxId + 1);
        } else {
          setLastItemId(1); // Handle the case where Items is empty
        }
      }
    });
  }, []);
  return (
    <form onSubmit={handleSubmit} className="Upload_hotel_form">
      <input
        type="text"
        name="id"
        value={(formData.id = lastItemId.toString())}
        onChange={handleChange}
        placeholder="ID"
        required
      />
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Travels Name"
        required
      />

      <input
        type="text"
        name="car"
        value={formData.car}
        onChange={handleChange}
        placeholder="Vehicle Name"
        required
      />
      <label className="Upload_Info">Image</label>
      <input type="file" name="imgurl" onChange={handleFileChange} required />
      <input
        type="number"
        name="number"
        value={formData.number}
        onChange={handleChange}
        placeholder="Mobile No"
        required
      />
      <input
        type="text"
        name="pkg"
        value={formData.pkg}
        onChange={handleChange}
        placeholder="Starting Rate"
        required
      />
      <input
        type="text"
        name="sitting"
        value={formData.sitting}
        onChange={handleChange}
        placeholder="Seating capacity 5+1"
        required
      />
      <div>
        <button type="submit" className="Upload_btn">
          <span>{loading && <ClipLoader />}</span>
          <span>Submit</span>
        </button>
      </div>
    </form>
  );
};

export default PostVehicleData;
