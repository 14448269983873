import Footer from "../../page/Footer";
import Navbar from "../../page/Navbar";
import "./Terms.css";
import { useEffect } from "react";

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="Terms_contant">
        <h1>Terms & Conditions</h1>
        <p>Last Updated Date: 08-08-2024, 11.00 AM</p>
        <p>
          <h3>Introduction</h3>
          <h4>
            Welcome to OotyGo Tours and Travel. By booking or buying our
            services, you agree to the following terms and conditions.
          </h4>

          <h4>Bookings and Reservations</h4>
          <p>
            All bookings are subject to availability and confirmation. A booking
            is confirmed once a deposit or full payment is received.
          </p>

          <h4>Subscription Payments</h4>
          <p>
            We accept credit cards, bank transfers, and other specified payment
            methods for subscription.
          </p>
          <h4>Booking Payments</h4>
          <p>
            By booking directly, you can make secure payments directly to the
            hotel or property owner's authorized business account or another
            account suggested by the owner.
          </p>
          <h4>Payment Terms</h4>
          <p>
            You agree to bear all risks and liabilities associated with payment
            processing, including any errors, disputes, or losses. So please be
            carfull while payment.
          </p>

          <h4>Cancellations and Refunds</h4>
          <p>
            Bookings can be cancelled up to 10 days prior to the tour date for a
            refund. Cancellations made within this timeframe may incur booking
            loss charges. Refunds will be processed within 3 to 7 bank working
            days.
          </p>

          <h4>Changes to Bookings</h4>
          <p>
            Customers can request changes to their bookings up to 10 days before
            the tour date. Additional fees may apply.
          </p>

          <h4>Limitation of Liability</h4>
          <p>
            OotyGo is not liable for delays or cancellations due to unforeseen
            circumstances. Customers are responsible for their own actions
            during the tour.
          </p>
          <h4>Special Requests</h4>
          <p>
            Please inform us of any special requests at the time of booking. We
            will do our best to accommodate them.
          </p>
          <h4>Safety and Conduct</h4>
          <p>
            Customers must follow all safety guidelines and behave respectfully
            during the tour.
          </p>
          <h4>Complaints</h4>
          <p>
            If you have any complaints, please contact us within 7 days of the
            tour. We will address your concerns promptly.
          </p>
          <h4>Data Protection</h4>
          <p>
            We value your privacy. Please refer to our privacy policy for
            details on how we protect your data.
          </p>
          <h4>Governing Law *</h4>
          <p>
            These terms and conditions are governed by the laws of Tamil Nadu,
            India.
          </p>
        </p>
        <hr />
        <p>
          <h3>Service Level Agreement (SLA) for OotyGo</h3>
          <h4>Parties Involved:</h4> • Service Provider: OotyGo Technologies
          (www.ootygo.in) <br /> • Customers: All registered users of OotyGo{" "}
          <h4>1. Purpose</h4>
          This SLA outlines the terms and conditions for services provided by
          OotyGo to its customers. <h4>2. Services Provided</h4> • Booking
          Services: Secure and efficient booking of tours, hotels, and taxis.{" "}
          <br /> • Customer Support: Monday to Saturday 10 am to 9 pm support
          for booking-related issues. <h4>3. Service Availability</h4> • Uptime
          Guarantee: OotyGo guarantees 99.9% uptime for its booking platform.{" "}
          <br /> • Maintenance: Scheduled maintenance will be communicated at
          least 48 hours in advance. <h4>4. Response and Resolution Time</h4> •
          Response Time: All customer inquiries will be responded to within 48
          hours. <br /> • Resolution Time: Issues will be resolved within 72
          hours from the time of reporting. <h4>5. Security and Compliance</h4>{" "}
          • Data Security: OotyGo complies with GDPR to ensure the security of
          customer data. <br /> • Fraud Prevention: Continuous monitoring and
          fraud detection mechanisms are in place. <h4>6. Responsibilities</h4>{" "}
          • OotyGo: Ensure the platform is operational and provide timely
          support. <br /> • Customers: Provide accurate information and comply
          with the terms of service. <h4>7. Amendments</h4> • Amendment
          Procedure: This SLA may be amended with mutual consent of both
          parties. Any amendments will be communicated in Email.
        </p>
        <p>
          <hr />
          <h3>Privacy Policy</h3>
          <h4>
            Introduction OotyGo is committed to protecting your privacy. This
            Privacy Policy explains how we collect, use, and safeguard your
            personal information when you visit our website, ootygo.in, and use
            our services.
          </h4>
          <h4>Information We Collect</h4>
          <h5>Personal Information:</h5>{" "}
          <p>
            When you book a tour or travel service, we collect personal
            information such as your name, email address, phone number, and
            payment details.
          </p>
          <h4>Non-Personal Information:</h4>{" "}
          <p>
            We may collect non-personal information such as your browser type,
            IP address, and browsing behavior on our website.{" "}
          </p>
          <h4>How We Use Your Information</h4>
          <h5>To Provide Services:</h5>
          <p>
            We use your personal information to process bookings, manage your
            account, and provide customer support.
          </p>
          <h4>To Improve Our Services:</h4>
          <p>
            We analyze non-personal information to improve our website and
            services.
          </p>
          <h4>Marketing:</h4>
          <p>
            With your consent, we may send you promotional emails about our
            services and special offers.{" "}
          </p>
          <h4>Sharing Your Information</h4>
          <h5>Third-Party Service Providers:</h5>
          <p>
            We may share your information with third-party service providers who
            assist us in operating our website and providing our services.
          </p>
          <h4>Legal Requirements:</h4>
          <p>
            We may disclose your information if required by law or in response
            to legal requests.
          </p>
          <h4>Cookies</h4>{" "}
          <p>
            Our website uses cookies to enhance your browsing experience.
            Cookies are small files stored on your device that help us
            understand how you use our website and improve our services.
          </p>
          <h4>Data Security</h4>
          <p>
            We implement appropriate security measures to protect your personal
            information from unauthorized access, alteration, disclosure, or
            destruction.
          </p>
          <h3>Your Rights</h3>
          <p>
            <h4>Access and Correction:</h4>{" "}
            <p>
              You have the right to access and correct your personal
              information.
            </p>
            <h4>Opt-Out:</h4>{" "}
            <p>
              You can opt-out of receiving marketing emails from us by following
              the unsubscribe link in the emails.
            </p>
            <h4>Changes to This Privacy Policy</h4>{" "}
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on our
              website or by Email
            </p>
          </p>
          <p>
            Contact Us If you have any questions or concerns about this Privacy
            Policy, please contact us at ootygo.official@gmail.com
          </p>
        </p>
      </div>
      <Footer />
    </>
  );
}
