import React, { useEffect } from "react";
import Navbar from "../page/Navbar";
import Contant from "../page/Contant";
import Footer from "../page/Footer";
import Time from "../page/Timer";
import "./Home.css";
import { Helmet } from "react-helmet";
function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const videoHight = window.innerHeight;
  return (
    <>
      <Helmet>
        <title>
          Ooty Go - Premier Travel Services in Ooty | Book Your Dream Vacation
          Now
        </title>
        <meta
          name="description"
          content="Explore the beauty of Ooty with OotyGo's personalized tour packages. Experience hassle-free bookings, luxury accommodations, and guided tours. Discover Ooty like never before!"
        />
        <meta
          name="keywords"
          content="Ooty Hotels, Hotel Booking, Best Hotels in Ooty, Affordable Accommodations, Luxury Stays, Ooty Resorts, Online Reservations, Mountain View Rooms, Family-Friendly Hotels, Business Travelers, Weekend Getaways, Honeymoon Suites, Central Location, Lake View Rooms, Group Bookings, Pet-Friendly Hotels, Ooty Accommodations, Book Direct, Last-Minute Deals, Boutique Hotels"
        />
      </Helmet>
      <Navbar />
      <div className="Home_body">
        <div className="Home_video_container">
          <video
            src="https://ootygo-travel-partner.s3.ap-south-1.amazonaws.com/video_20241019_201338.mp4"
            muted
            autoPlay
            width="100%"
            height={videoHight}
            className="Home_video"
            controlsList="nodownload"
          ></video>
          <h1 className="Home_video_titel">Welcome to Ooty</h1>
          <div className="gradient-overlay"></div>
        </div>
        <Time />
        <Contant />
      </div>
      <Footer />
    </>
  );
}
export default Home;
